//external libs
import { useState, useEffect } from 'react';

//components
import xDecoration from './images/scrolling-text-x.svg';

//styling
import styling from './ScrollingText.module.css';

//other
import { getLiquidSpaceSummary } from '../../web3layer/connectLiquidSpace';

const ScrollingTextX = () => {
  return (
    <span>
      <img src={xDecoration} alt="x for decoration" />
    </span>
  );
};

const ScrollingText = () => {
  const [liquidSpaceSummary, setLiquidSpaceSummary] = useState({});

  useEffect(() => {
    getLiquidSpaceSummary().then((contractResponse) => {
      let summary = {};
      summary.numberOfRegisteredAccounts = contractResponse.numberOfRegisteredAccounts;
      summary.numberOfTradedFungibleTokens = contractResponse.numberOfTradedFungibleTokens;
      summary.numberOfTradedNfts = contractResponse.numberOfTradedNfts;
      setLiquidSpaceSummary(summary);
    });
  }, []);

  return (
    <div className={styling.container}>
      <div className={styling.marquee}>
        <span>{liquidSpaceSummary.numberOfRegisteredAccounts} OWNERS </span>
        <ScrollingTextX />
        <span>{liquidSpaceSummary.numberOfTradedNfts} NFTs MINTED</span>
        <ScrollingTextX />
        <span>{liquidSpaceSummary.numberOfTradedFungibleTokens} LIQUID TOKENS </span>
        <ScrollingTextX />
      </div>
    </div>
  );
};

export default ScrollingText;
