//styling
import styling from './nftCardActionModal.module.css';

//components
import Button from '../ui/Button';

import { useState } from 'react';

const NFTCardActionModal = (props) => {
  const [salePrice, setSalePrice] = useState(0);
  const conditionalHeader = () => {
    if (props.cardActionState === 'SELL') {
      return 'Sale';
    }
    if (props.cardActionState === 'BUY') {
      return 'Purchase';
    }
    if (props.cardActionState === 'WITHDRAW') {
      return 'Withdraw';
    }
  };

  const conditionalCallToAction = () => {
    if (props.cardActionState === 'SELL') {
      return 'You are about to list this NFT on the marketplace';
    }
    if (props.cardActionState === 'BUY') {
      return 'You are about to purchase this NFT';
    }
    if (props.cardActionState === 'WITHDRAW') {
      return 'You are about to withdraw this NFT from the marketplace';
    }
  };

  // Conditional submit logic to be filled in...
  const handleConditionalSubmit = () => {
    console.log('Button logic to follow soon');
  };

  return (
    <>
      <div className={styling.modalContainer} onClick={() => props.setToggleModal(false)}>
        <div className={styling.modalInner} onClick={(e) => e.stopPropagation()}>
          <h2 className={styling.modalHeader}>Confirm {conditionalHeader()}</h2>
          <img className={styling.modalNFTThumbnail} alt={'An NFT'} src={props.src}></img>
          <p className={styling.modalNFTName}>
            <strong>{props.nftName}</strong>
          </p>
          <p className={styling.modalCallToAction}>{conditionalCallToAction()}</p>
          {props.cardActionState === 'BUY' && (
            <div className={styling.balanceContainer}>
              <p className={styling.modalContentText}>Balance:&nbsp;</p>
              <p className={styling.modalContentPriceText}>{Number(props.currentUserBalance)}</p>
              <p className={styling.modalContentText}>&nbsp; LT</p>
            </div>
          )}
          {props.cardActionState === 'SELL' && (
            <div className={styling.salePriceContainer}>
              <h2>Sell Price: &nbsp;</h2>
              <div className={styling.sellPriceWrapper}>
                <input
                  className={styling.sellPriceInput}
                  value={salePrice}
                  type={'text'}
                  onChange={(e) => {
                    setSalePrice(Number(e.target.value));
                  }}
                ></input>
              </div>
              <h2>&nbsp; LT</h2>
            </div>
          )}
          {props.cardActionState === 'BUY' && (
            <div className={styling.salePriceContainer}>
              <h2>Price: &nbsp;</h2>
              <h2 className={styling.price}>{props.nftPrice}</h2>
              <h2>&nbsp; LT</h2>
            </div>
          )}
          <div className={styling.buttonContainer}>
            <Button handler={handleConditionalSubmit()} disabled={props.cardActionState === 'SELL' && salePrice <= 0}>
              Accept
            </Button>
            <Button
              buttonStyle={styling.rejectButton}
              buttonType={'button'}
              handler={() => {
                props.setToggleModal(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NFTCardActionModal;
