//External libs
import axios from 'axios';

//External modules
import { mintNftOffChain } from '../web3layer/mintNft';
import { mintNftOnChain } from '../web3layer/nft';

import { File } from 'nft.storage';

// Components
import CreateNftsForm from '../components/create-nfts/CreateNftsForm';
import Footer from '../components/ui/Footer';
import NavBar from '../components/ui/NavBar';
import XXDecorationBar from '../components/ui/XXDecorationBar';

// Styling
import styling from './CreateNftsPage.module.css';

//TODO: Once the nft is minted, user should be taken to their profile page?
//TODO: Add loading screen?
//TODO: Add smart contract minting upon submission. Here or in create nft form?

const CreateNftsPage = (props) => {
  const mintNewNftHandler = (enteredNftMetaData) => {
    //TODO: 'liquid-space' in created file can be replaced with the hash of an image. We want to keep it
    //TODO: the same so that if an image uploaded twice would return the same image url.
    let newNftFile = new File([enteredNftMetaData.file], 'liquid-space', { type: 'image/jpeg' });
    const newNft = { ...enteredNftMetaData, creatorAddress: props.thisUserAddress, file: newNftFile };
    let nftOnChainMetaData;
    mintNftOffChain(newNft)
      .then((ipfsMetadata) => {
        axios
          .get(`https://cloudflare-ipfs.com/ipfs/${ipfsMetadata.ipnft}/metadata.json`)
          .then((response) => {
            if (response.status === 200) {
              nftOnChainMetaData = {
                ipnftHash: ipfsMetadata.ipnft,
                nftIpfs: response.data.image.replace('ipfs://', ''),
                royalty: enteredNftMetaData.royalty,
              };
            }
          })
          .then(() => {
            mintNftOnChain(nftOnChainMetaData);
          });
      })
      .catch(() => {
        console.error('Error in minting NFT off chain');
      });
  };

  return (
    <div>
      <NavBar />
      <XXDecorationBar />
      <div className={styling.container}>
        <div className={styling.blurb}>
          <h1>CREATE YOUR OWN NFTS</h1>
          <p>Buy art, trade coins, and learn all about crypto currencies.</p>
        </div>
        <div className={styling.nftForm}>
          <CreateNftsForm onCreateNft={mintNewNftHandler} />
        </div>
      </div>
      <XXDecorationBar />
      <Footer />
    </div>
  );
};

export default CreateNftsPage;
