import React from 'react';
import { useNavigate } from 'react-router-dom';

//components
import Button from '../../components/ui/Button';
import XXDecorationBar from '../../components/ui/XXDecorationBar';

//styling
import styling from './Welcome.module.css';

//others
import welcomeBust from '../ui/images/welcome-bust.svg';

function Welcome() {
  const navigate = useNavigate();
  return (
    <>
      <section className={styling.container}>
        <XXDecorationBar />
        <section className={styling.wrapper}>
          <div className={styling.blurb}>
            <h1>
              WELCOME TO<br></br>
              LIQUID SPACE<br></br>
              marketplace<br></br>
            </h1>
            <p>Buy art, trade coins, and learn all about crypto currencies.</p>
            <Button handler={() => navigate('/create')}>Create</Button>
          </div>
          <div className={styling.image}>
            <img src={welcomeBust} alt="a marble statue bust" />
          </div>
        </section>
        <XXDecorationBar />
      </section>
    </>
  );
}

export default Welcome;
