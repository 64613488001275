//styling
import styling from './AccountSummaryItems.module.css';

const AccountSummaryItems = (props) => {
  return (
    <div>
      <p>{props.title}</p>
      <p className={styling.content}>{props.value}</p>
    </div>
  );
};

export default AccountSummaryItems;
