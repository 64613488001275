import { getLiquidSpaceContractWithProvider } from './connectLiquidSpace';
import { getLiquidSpaceContractWithSigner } from './connectLiquidSpace';

export const getAllNftIpfsHashesForMarketplace = async () => {
  const readOnlyLiquidSpaceContractInstance = await getLiquidSpaceContractWithProvider();
  try {
    const allIpfsHashes = await readOnlyLiquidSpaceContractInstance.functions.getLiquidSpaceIpfsHashes();
    return allIpfsHashes;
  } catch (error) {
    console.error(`Error in getting liquid space summary: ${JSON.stringify(error, null, 2)}`);
  }
};

export const mintNftOnChain = async (nftMetaData) => {
  const liquidSpaceContractInstanceWithSigner = await getLiquidSpaceContractWithSigner();
  try {
    return await liquidSpaceContractInstanceWithSigner.mintNft(nftMetaData);
  } catch (error) {
    console.error(`Error in minting new nft on chain: ${JSON.stringify(error, null, 2)}`);
  }
};
