//styling
import styling from './Banner.module.css';

//others
import bannerAnimation from './images/bannerAnimation.mp4';

const Banner = () => {
  return (
    <div className={styling.container}>
      <video autoPlay muted loop>
        <source src={bannerAnimation} type="video/mp4" />
      </video>
    </div>
  );
};

export default Banner;
