//external libs
import { Link } from 'react-router-dom';

//styling
import styling from './LinkingList.module.css';

const LinkingList = (props) => {
  return (
    <Link to={props.to}>
      <li className={styling.list}>{props.title}</li>
    </Link>
  );
};

export default LinkingList;
