//styling
import styling from './Tab.module.css';

const Tab = (props) => {
  let classes = props.isActive ? styling.tabsActive : styling.tabs;

  return (
    <button className={classes} onClick={props.handler} disabled={props.isActive}>
      {props.children}
    </button>
  );
};

export default Tab;
