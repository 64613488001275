//styling
import styling from './Button.module.css';

const Button = (props) => {
  return (
    <button
      type={props.buttonType || 'submit'}
      className={props.buttonStyle || styling.button}
      onClick={props.handler}
      disabled={props.disabled === true ? true : false}
    >
      {props.children}
    </button>
  );
};

export default Button;
