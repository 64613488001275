//styling
import styling from './XXXXBlock.module.css';

//other
import xxxxSquare from './images/xxxx-square-block.svg';
import xxxxRectangle from './images/xxxx-rectangle-block.svg';

const XXXXBlock = (props) => {
  const classes = styling.wrapper + ' ' + props.className;
  let imageSource;
  if (props.type === 'square') {
    imageSource = xxxxSquare;
  } else if (props.type === 'rectangle') {
    imageSource = xxxxRectangle;
  }

  return (
    <div>
      <div className={classes}>
        <img src={imageSource} alt="square block made up of x's" />
      </div>
    </div>
  );
};

export default XXXXBlock;
