//external libs
import { useState } from 'react';

//styling
import styling from './cardFrontView.module.css';

//components
import eyeIcon from '../ui/images/eyeIcon.svg';
import { CardExpandedView } from './CardExpandedView';

export const CardViewFront = (props) => {
  const [expandedView, toggleExpandedView] = useState(false);
  const handleCloseModal = () => {
    props.setShowCardFront(false);
  };

  return (
    <>
      <div
        className={styling.container}
        onClick={() => {
          handleCloseModal();
        }}
      >
        <div className={styling.inner} onClick={(e) => e.stopPropagation()}>
          <div className={styling.nftEnlargeViewOverlay} onClick={() => toggleExpandedView(true)}>
            <img className={styling.modalNFTThumbnail} alt={'An NFT'} src={props.src} width={500}></img>
            <div className={styling.hoverContent}>
              <img src={eyeIcon} className={styling.eyeIcon}></img>
              <p>Click to enlarge</p>
            </div>
          </div>
          <div className={styling.nftDetails}>
            <p className={styling.nftHeader}>{props.nftName}</p>
            <div className={styling.contentDescription}>
              <p>
                <em>"{props.nftDescription}"</em>
              </p>
            </div>
            <hr className={styling.lineBreak} />
            <div className={styling.contentSection}>
              <table className={styling.nftDetailsTable}>
                <colgroup span="3" width="140px"></colgroup>
                <tbody>
                  <tr>
                    <td className={styling.tableCell}>
                      <strong>Current owner: </strong>
                    </td>
                    <td className={styling.tableCell}></td>
                    <td className={styling.tableCell}>{props.nftOwner}</td>
                  </tr>
                  <tr>
                    <td className={styling.tableCell}>
                      <strong>Created by:</strong>{' '}
                    </td>
                    <td className={styling.tableCell}></td>
                    <td className={styling.tableCell}>{props.nftCreator}</td>
                  </tr>
                  <tr>
                    <td className={styling.tableCell}>
                      <strong>Last sale price:</strong>{' '}
                    </td>
                    <td className={styling.tableCell}></td>
                    <td className={styling.tableCell}>
                      {props.transactionHistory.length > 0
                        ? `${props.transactionHistory[props.transactionHistory.length - 1]} LT`
                        : 'N/A'}{' '}
                      LT
                    </td>
                  </tr>
                  <tr>
                    <td className={styling.tableCell}>
                      <strong>Attributes: </strong>
                    </td>
                    <td className={styling.tableCell}></td>
                    <td className={styling.tableCell}></td>
                  </tr>
                  {props.nftAttributes.map((attribute) => {
                    return (
                      <tr>
                        <td className={styling.tableCell}> </td>
                        <td className={styling.tableCell}>{Object.keys(attribute)}: </td>
                        <td className={styling.tableCell}>{Object.values(attribute)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <hr className={styling.lineBreak} />
            <div className={styling.contentSection}>
              <p>
                {props.nftPrice > 0
                  ? `This NFT is for sale for ${props.nftPrice} LT`
                  : 'This NFT is not currently for sale.'}
              </p>
            </div>
          </div>
        </div>
      </div>
      {expandedView && <CardExpandedView src={props.src} toggleExpandedView={toggleExpandedView} />}
    </>
  );
};
