//external libs
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

//styling
import styling from './NFTCard.module.css';

//components
import Button from '../ui/Button';
import NFTCardActionModal from './NFTCardActionModal';
import { CardViewFront } from './CardFrontView';

function NFTCard(props) {
  const currentPage = useLocation();
  const [toggleModal, setToggleModal] = useState(false);
  const [showCardFront, setShowCardFront] = useState(false);
  const [cardActionState, setCardActionState] = useState('');

  const conditionalButtonStates = (currentUser) => {
    if (currentPage.pathname === '/marketplace') {
      setCardActionState(props.nftOwnerAddress === currentUser ? 'WITHDRAW' : 'BUY');
      return props.nftOwner === currentUser ? 'WITHDRAW' : 'BUY';
    }
    if (currentPage.pathname === '/account') {
      setCardActionState('SELL');
      return 'SELL';
    }
  };
  useEffect(() => {
    const currentUser = props.thisUserAddress;
    conditionalButtonStates(currentUser);
  }, []);

  const handleViewDetails = (e) => {
    e.stopPropagation();
    setShowCardFront(true);
  };

  return (
    <>
      <div className={styling.overlay}>
        <img src={props.src} alt={`A user owned NFT`} className={styling.individualNFT} width={265}></img>
        <div className={styling.NFTContents}>
          <div className={styling.contentContainer}>
            <div className={styling.nftDetails}>
              <p className={styling.cardHeader}>{props.nftName}</p>
              <p className={styling.cardText}>@{props.nftOwner}</p>
              <p className={styling.cardText} onClick={(e) => handleViewDetails(e)}>
                View Details
              </p>
            </div>
            <div className={styling.nftMarketInfo}>
              <p className={styling.cardHeader}>{props.nftPrice} LT</p>
              <>
                <Button buttonStyle={styling.nftButton} buttonType={'button'} handler={(e) => setToggleModal(true)}>
                  {cardActionState}
                </Button>
              </>
            </div>
          </div>
        </div>
      </div>
      {toggleModal && (
        <NFTCardActionModal
          src={props.src}
          nftName={props.nftName}
          nftPrice={props.nftPrice}
          setToggleModal={setToggleModal}
          cardActionState={cardActionState}
          currentUserBalance={props.currentUserBalance}
        />
      )}
      {showCardFront && (
        <CardViewFront
          src={props.src}
          nftName={props.nftName}
          nftPrice={props.nftPrice}
          nftOwner={props.nftOwner}
          nftCreator={props.nftCreator}
          setShowCardFront={setShowCardFront}
          currentUserBalance={props.currentUserBalance}
          nftDescription={props.nftDescription}
          nftAttributes={props.nftAttributes}
          transactionHistory={props.transactionHistory}
        />
      )}
    </>
  );
}

export default NFTCard;
