//external libs
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

//components
import Button from '../ui/Button';

//other
import { registerLiquidName } from '../../web3layer/connectLiquidSpace';

//styling
import styling from './UserNameForn.module.css';
import tickWhite from '../ui/images/tick-white.svg';

export const UserNameForm = (props) => {
  const liquidNameRef = useRef();

  const navigate = useNavigate();

  const liquidNameRegistrationHandler = (liquidName) => {
    registerLiquidName(liquidName)
      .then((transaction) => {
        return transaction.wait();
      })
      .then(() => {
        navigate(0);
      })
      .catch(() => {
        console.error('Error in liquid name registration handler');
      });
  };

  const submitHandler = (event) => {
    event.preventDefault();

    const liquidName = liquidNameRef.current.value;

    liquidNameRegistrationHandler(liquidName);
  };

  const handleToolTipClose = () => {
    const usernameToolTip = document.getElementById('toolTip');
    usernameToolTip.style.visibility = 'hidden';
  };

  return (
    <>
      {!props.liquidName && (
        <div className={styling.formContainer}>
          <div className={styling.toolTip} id={'toolTip'}>
            <span className={styling.cancelToolTip} onClick={handleToolTipClose}>
              x
            </span>
            Give yourself a username to instantly earn your first liquid tokens!
          </div>
          <input className={styling.usernameForm} type="text" required ref={liquidNameRef} placeholder="Username" />
          <Button className={styling.formButton} handler={submitHandler}>
            <img src={tickWhite} alt="White tick" />
          </Button>
        </div>
      )}
      {props.liquidName && (
        <div className={styling.usernameContainer}>
          <h2 className={styling.usernameDisplay}>@{props.liquidName}</h2>
        </div>
      )}
    </>
  );
};

export default UserNameForm;
