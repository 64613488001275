//external libs
import { useState } from 'react';

//components
import Tab from '../ui/Tab';
import XXDecorationBar from '../ui/XXDecorationBar';
import AccountAssets from './AccountAssets';

//styling
import styling from './AccountAssetsTab.module.css';

const AccountAssetsTab = (props) => {
  const [toggleState, setToggleState] = useState({ nft: true, badge: false });
  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div className={styling.container}>
      <div className="account-asset-bloc-tabs">
        <Tab handler={() => toggleTab({ nft: true, badge: false })} isActive={toggleState.nft}>
          NFTs
        </Tab>
        <Tab handler={() => toggleTab({ nft: false, badge: true })} isActive={toggleState.badge}>
          BADGES
        </Tab>
      </div>
      <XXDecorationBar />
      <div className={styling.content}>
        <AccountAssets
          message={"You don't have any NFT's \xa0\xa0\xa0 :("}
          type={'nft'}
          isActive={toggleState.nft}
          NFTPayload={props.NFTPayload}
          thisUserAddress={props.thisUserAddress}
        />
        <AccountAssets
          message={"You don't have any badges \xa0\xa0\xa0 :("}
          type={'badge'}
          isActive={toggleState.badge}
        />
      </div>
      <XXDecorationBar />
    </div>
  );
};

export default AccountAssetsTab;
