//styling
import styling from './ScrollDownPageBreak.module.css';

//others
import scrollDownArrow from './images/scroll-down-arrow.svg';

const ScrollDownPageBreak = () => {
  return (
    <>
      <div className={styling.container}>
        <div>
          <p>Scroll Down To Find</p>
          <p>Out More</p>
          <img src={scrollDownArrow} alt="downward pointing arrow" />
        </div>
      </div>
    </>
  );
};

export default ScrollDownPageBreak;
