//components
import ConnectWallet from '../components/connect-wallet/ConnectWallet';
import LiquidSpaceLogo from '../components/ui/LiquidSpaceLogo';

function ConnectWalletPage() {
  return (
    <div>
      <section>
        <LiquidSpaceLogo />
      </section>
      <section>
        <ConnectWallet />
      </section>
    </div>
  );
}

export default ConnectWalletPage;
