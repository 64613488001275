//styling
import styling from './cardExpandedView.module.css';

export const CardExpandedView = (props) => {
  const handleCloseModal = (event) => {
    props.toggleExpandedView(false);
    event.stopPropagation();
  };

  return (
    <>
      <div className={styling.expandedImageBackdrop} onClick={(e) => handleCloseModal(e)}>
        <img className={styling.expandedImage} src={props.src}></img>
      </div>
    </>
  );
};
