//external libs
import { useEffect } from 'react';

//styling
import styling from './nftGallery.module.css';

//components
import NFTCard from './NFTCard';

// Main component that's rendered
const NFTGallery = (props) => {
  let columnCount = Math.min(props.NFTPayload.length, props.columnCount);
  const itemCount = Math.max(props.NFTPayload.length - 1, 1);
  const rowCount = Math.ceil(itemCount / columnCount);
  let innerNFTArray = [...new Array(columnCount)].map(() => new Array());

  const createNFTGalleryColumns = () => {
    let outerCounter = 0;
    // Nested loop to be flattened/ complexity reduced
    for (let column = 0; column < columnCount; column++) {
      for (let individualNFT = 0; individualNFT < rowCount; individualNFT++) {
        innerNFTArray[column].push(props.NFTPayload[outerCounter]);
        outerCounter++;
      }
    }
  };

  useEffect(() => {
    if (props.setHasNFT) {
      props.setHasNFT(true);
    }
  }, [props.NFTPayload]);

  createNFTGalleryColumns();

  return (
    <>
      <div className={styling.outerContainer}>
        {props.NFTPayload.length > 0 && (
          <>
            <div className={styling.nftGalleryContainer}>
              {innerNFTArray &&
                innerNFTArray.map((columnContainer, outerIdx) => {
                  return (
                    <div key={outerIdx} className={styling.nftColumnContainer}>
                      {columnContainer.map((innerImage, innerIdx) => {
                        if (innerImage !== undefined) {
                          return (
                            <>
                              <NFTCard
                                src={innerImage.nftInformation.imageSrc}
                                nftName={innerImage.name}
                                nftCreator={innerImage.nftInformation.creator}
                                nftPrice={innerImage.price}
                                nftOwner={innerImage.ownerUsername}
                                nftOwnerAddress={innerImage?.ownerAddress}
                                thisUserAddress={props.thisUserAddress}
                                currentUserBalance={props.currentUserBalance}
                                nftDescription={innerImage.nftInformation.description}
                                nftAttributes={innerImage.nftInformation.attributes}
                                transactionHistory={innerImage.transactionHistory}
                              />
                            </>
                          );
                        }
                      })}
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default NFTGallery;
