//components
import Button from '../ui/Button';
import XXXXBlock from '../ui/XXXXBlock';
import XXDecorationBar from '../ui/XXDecorationBar';
//styling
import styling from './UserSteps.module.css';

const UserStep = (props) => {
  return (
    <div className={styling.content}>
      <div className={styling.number} data-text={props.number}>
        {props.number}
      </div>
      <h1>{props.mainTitle}</h1>
      <p>{props.paragraph}</p>
    </div>
  );
};

const UserSteps = () => {
  const userStepContents = [
    {
      number: '01',
      mainTitle: 'CONNECT WALLET',
      paragraph:
        ' Setup and connect a Metamask wallet to activate your account - no need to register your account on Liquid Space.',
    },
    {
      number: '02',
      mainTitle: 'RECEIVE TOKENS',
      paragraph: 'Once you connect your Metamask wallet you will receive some free Liquid Tokens from us!',
    },
    {
      number: '03',
      mainTitle: 'PURCHASE NFTS',
      paragraph: 'You can use your tokens to buy an AI generated NFT from our Liquid Space marketplace.',
    },
    {
      number: '04',
      mainTitle: 'TRADE WITH COMMUNITY',
      paragraph: 'We’re working on a new feature to trade tokens and gift badges with other members of our community.',
    },
  ];

  return (
    <div className={styling.overallContainer}>
      <div className={styling.container}>
        <XXDecorationBar />
        <div className={styling.wrapper}>
          {userStepContents.map((userStep) => (
            <UserStep
              key={userStep.number}
              number={userStep.number}
              mainTitle={userStep.mainTitle}
              paragraph={userStep.paragraph}
            />
          ))}
        </div>
        <Button>Get Started</Button>
        <XXDecorationBar />
      </div>
      <XXXXBlock type={'rectangle'} />
    </div>
  );
};

export default UserSteps;
