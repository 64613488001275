//external libs
import { Link } from 'react-router-dom';

//styling
import styling from './LiquidSpaceLogo.module.css';

const LiquidSpaceLogo = () => {
  return (
    <Link to="/">
      <div className={styling.logo} data-text="LIQUID SPACE">
        LIQUID SPACE
      </div>
    </Link>
  );
};

export default LiquidSpaceLogo;
