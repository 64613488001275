import { NFTStorage } from 'nft.storage';

export async function mintNftOffChain(nftMetaData) {
  const client = new NFTStorage({ token: process.env.REACT_APP_NFT_STORAGE });
  const metadata = await client.store({
    name: nftMetaData.name,
    creatorAddress: nftMetaData.creatorAddress,
    description: nftMetaData.description,
    image: nftMetaData.file,
    attributes: nftMetaData.attributes,
    royalty: nftMetaData.royalty,
  });
  /**
   * Fetching https://cloudflare-ipfs.com/{metadata.ipnft}/metadata.json will return the metadata
   * Once metadata is fetched,  https://cloudflare-ipfs.com/{metadata.image} will return the image
   * Remove ipfs:// from metadata.image
   */
  return metadata;
}
