import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import LandingPage from './pages/LandingPage';
import WalletConnectPage from './pages/ConnectWalletPage';
import MarketPlace from './pages/Marketplace';
import AccountPage from './pages/AccountPage';
import CreateNftsPage from './pages/CreateNftsPage';
import './globalStylesheet.css';
import { getAccountAddress } from './web3layer/utils';

function App() {
  const [currentUserBlockchainAddress, setCurrentUserBlockchainAddress] = useState('');
  useEffect(() => {
    getAccountAddress().then((address) => {
      let currentUserAddress = address.signerAddress;
      setCurrentUserBlockchainAddress(currentUserAddress);
    });
  });
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/connect" element={<WalletConnectPage />} />
        <Route path="/account" element={<AccountPage thisUserAddress={currentUserBlockchainAddress} />} />
        <Route path="/marketplace" element={<MarketPlace thisUserAddress={currentUserBlockchainAddress} />} />
        <Route path="/create" element={<CreateNftsPage thisUserAddress={currentUserBlockchainAddress} />} />
      </Routes>
    </Router>
  );
}

export default App;
