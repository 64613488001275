//external libs
import { useEffect, useState } from 'react';
import axios from 'axios';

//components
import NFTGallery from '../components/shared/NFTGallery';
import Welcome from '../components/marketplace/Welcome';
import Footer from '../components/ui/Footer';
import NavBar from '../components/ui/NavBar';
import XXDecorationBar from '../components/ui/XXDecorationBar';

//functions
import { getAccountSummary } from '../web3layer/connectLiquidSpace';
import { getAllNftIpfsHashesForMarketplace } from '../web3layer/nft';
import { processCloudFlareImageSrc } from '../helpers/helpers';

//styling
import styling from './Marketplace.module.css';
import { nftDummyData } from '../helpers/helpers';

function Marketplace(props) {
  const [currentUserBalance, setCurrentUserBalance] = useState('');
  const [nftHashArray, setNftHashArray] = useState([]);
  const [nftMetaDataPayload, setNftMetaDataPayload] = useState([]);

  const processIpfsImageSrc = (unprocessedSrc) => {
    let splitUrlSrc = unprocessedSrc.slice(7, unprocessedSrc.length - 1);
    return `https://cloudflare-ipfs.com/ipfs/${splitUrlSrc}`;
  };

  useEffect(() => {
    getAllNftIpfsHashesForMarketplace().then((allNftHashArray) => {
      setNftHashArray(allNftHashArray);
    });
    nftHashArray.forEach((hash) => {
      let metaDataObject = {
        imageSrc: '',
        name: '',
        description: '',
        ownerUsername: '',
        creator: '',
        attributes: [],
        price: '',
        transactionHistory: [],
      };

      axios
        .get(`https://cloudflare-ipfs.com/ipfs/${hash}/metadata.json`, {
          headers: { 'Access-Control-Allow-Origin': '*' },
        })
        .then((response) => {
          if (response.status === 200) {
            metaDataObject.name = response.data.name;
            metaDataObject.description = response.data.description;
            metaDataObject.ownerUsername = response.data.ownerUsername;
            metaDataObject.creator = response.data.creator;
            metaDataObject.price = response.data.price;
            metaDataObject.imageSrc = processCloudFlareImageSrc(response.data.image);
            metaDataObject.attributes = response.data.attributes;
            metaDataObject.transactionHistory =
              response.data.transactionHistory.length > 0 ? response.data.transactionHistory : 0;

            setNftMetaDataPayload((prevState) => [...prevState, metaDataObject]);
          }
        });
    });

    // Get dummy data
    setNftMetaDataPayload(nftDummyData);

    getAccountSummary().then((response) => {
      const accountBalance = response[0].liquidTokenBalance;
      setCurrentUserBalance(accountBalance);
    });
  }, []);

  return (
    <div>
      <NavBar />
      <Welcome />
      <NFTGallery
        columnCount={4}
        NFTPayload={nftMetaDataPayload}
        thisUserAddress={props.thisUserAddress}
        currentUserBalance={currentUserBalance}
      />
      <div className={styling.container}>
        <XXDecorationBar />
      </div>
      <Footer />
    </div>
  );
}

export default Marketplace;
