//styling
import styling from './ProfilePicture.module.css';

const AccountProfilePicture = () => {
  return (
    <div className={styling.image}>
      <img
        src="https://ipfs.io/ipfs/bafybeidefhg43ohahn3jygiyaksxmwxn3hzhs6652fylc3vv2dugrjtabq/Mountain2"
        alt="a profile pic"
      />
    </div>
  );
};

export default AccountProfilePicture;
