//components
import ProfilePicture from '../profile-picture/ProfilePicture';
import LiquidSpaceLogo from './LiquidSpaceLogo';
import LinkingList from './LinkingList';

//styling
import styling from './NavBar.module.css';

const NavBar = () => {
  return (
    <nav className={styling.container}>
      <LiquidSpaceLogo />
      <div className={styling.wrapper}>
        <div>
          <ul className={styling.list}>
            <LinkingList to={'/marketplace'} title={'Marketplace'} />
          </ul>
        </div>
        <div className={styling.image}>
          <ProfilePicture />
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
