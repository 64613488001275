//external libs
import { useState, useEffect } from 'react';

//components
import AccountSummaryItems from './AccountSummaryItems';
import ProfilePicturePlaceholder from '../profile-picture/ProfilePicturePlaceholder';
import Loading from '../ui/Loading';
import UserNameForm from './UserNameForm';

//styling
import styling from './AccountSummaryPanel.module.css';

//others
import { getAccountSummary } from '../../web3layer/connectLiquidSpace';
import { getAccountAddressAndEthBalance, rewardEth } from '../../web3layer/utils';

const AccountSummaryPanel = (props) => {
  const [liquidAccountSummary, setLiquidAccountSummary] = useState({});
  const [accountDetails, setAccountDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAccountSummary(props.thisUserAddress).then((contractResponse) => {
      setIsLoading(true);
      let summary = {
        liquidName: contractResponse.liquidName[0],
        liquidTokenBalance: +contractResponse.liquidTokenBalance,
        liquidNonFungibleSummary: contractResponse.liquidNftSummary,
      };
      setLiquidAccountSummary(summary);
      setIsLoading(false);
    });

    getAccountAddressAndEthBalance().then((accountDetails) => {
      setIsLoading(true);
      let account = {};
      account.ethBalance = accountDetails.accountEthBalance;
      account.accountAddress = accountDetails.signerAddress;
      if (/^true$/i.test(process.env.REACT_APP_HAS_ETH_REWARD) && account.ethBalance === '0.0') {
        rewardEth(account.accountAddress).catch((error) => {
          console.error(`Error in rewarding eth in account summary panel: ${(error, null, 2)}`);
        });
      }
      setAccountDetails(account);
      setIsLoading(false);
    });
  }, []);

  const chainChangedHandler = () => {
    window.location.reload();
  };

  const refreshAccountChangedHandler = () => {
    window.location.reload();
  };

  window.ethereum.on('chainChanged', chainChangedHandler);
  window.ethereum.on('accountsChanged', refreshAccountChangedHandler);

  return (
    <>
      <section className={styling.container}>
        <section className={styling.accountContainer}>
          <ProfilePicturePlaceholder />
          <UserNameForm liquidName={liquidAccountSummary.liquidName} />
        </section>
        {isLoading && <Loading />}
        {!isLoading && (
          <div className={styling.wrapper}>
            <AccountSummaryItems title={'Wallet Address'} value={accountDetails.accountAddress} />
            <AccountSummaryItems title={'NFTs'} value={liquidAccountSummary.liquidNonFungibleSummary} />
            <AccountSummaryItems title={'Liquid Token Balance'} value={liquidAccountSummary.liquidTokenBalance} />
            <AccountSummaryItems title={'Göerli Eth Balance'} value={accountDetails.ethBalance} />
          </div>
        )}
      </section>
    </>
  );
};

export default AccountSummaryPanel;
