import liquidSpaceArtifactPath from '../../src/smartContractArtifacts/hardhatArtifacts/contracts/LiquidSpace.sol/LiquidSpace.json';
import contractAddresses from '../../src/smartContractArtifacts/contractAddresses.json';
import { ethers } from 'ethers';
import { getSigner, getWeb3Provider } from './utils';

export const getLiquidSpaceContractWithSigner = async () => {
  try {
    const signer = await getSigner();

    /**
     *  /^0x/i.test(contractAddresses.LiquidSpace) will return false in production
     *  This is used to determine whether to use local or remote contract address
     *  This is needed as REACT_APP_LIQUID_SPACE_ADDRESS in local .env and liquidSpaceAddress create conflict
     */
    const liquidSpaceAddress = /^0x/i.test(contractAddresses.LiquidSpace)
      ? contractAddresses.LiquidSpace
      : process.env.REACT_APP_LIQUID_SPACE_ADDRESS;
    const contract = new ethers.Contract(liquidSpaceAddress, liquidSpaceArtifactPath.abi, signer);
    return contract.connect(signer);
  } catch (error) {
    console.error(`Error in getting liquid space contract with signer: ${JSON.stringify(error, null, 2)}`);
  }
};

export const getLiquidSpaceContractWithProvider = async () => {
  try {
    const web3Provider = await getWeb3Provider();
    /**
     *  /^0x/i.test(contractAddresses.LiquidSpace) will return false in production
     *  This is used to determine whether to use local or remote contract address
     *  This is needed as REACT_APP_LIQUID_SPACE_ADDRESS in local .env and liquidSpaceAddress create conflict
     */
    const liquidSpaceAddress = /^0x/i.test(contractAddresses.LiquidSpace)
      ? contractAddresses.LiquidSpace
      : process.env.REACT_APP_LIQUID_SPACE_ADDRESS;
    const contract = new ethers.Contract(liquidSpaceAddress, liquidSpaceArtifactPath.abi, web3Provider);
    return contract.connect(web3Provider);
  } catch (error) {
    console.error(`Error in getting liquid space contract with provider: ${JSON.stringify(error, null, 2)}`);
  }
};

export const getNetworkName = async () => {
  try {
    const provider = await getWeb3Provider();
    const network = await provider.getNetwork();
    return network.name;
  } catch (error) {
    console.error(`Error in getting network name: ${JSON.stringify(error, null, 2)}`);
  }
};

export const getLiquidSpaceSummary = async () => {
  const readOnlyLiquidSpaceContractInstance = await getLiquidSpaceContractWithProvider();
  try {
    const registeredAccounts = await readOnlyLiquidSpaceContractInstance.functions.getRegisteredAccounts();
    const liquidTokenTotalSupply = await readOnlyLiquidSpaceContractInstance.liquidTokenTotalSupply();
    const allNftMetaData = await readOnlyLiquidSpaceContractInstance.functions.getNftMetaData();

    const liquidSpaceSummary = {
      numberOfRegisteredAccounts: registeredAccounts.length,
      numberOfTradedFungibleTokens: liquidTokenTotalSupply,
      numberOfTradedNfts: allNftMetaData.length,
    };
    return liquidSpaceSummary;
  } catch (error) {
    console.error(`Error in getting liquid space summary: ${JSON.stringify(error, null, 2)}`);
  }
};

export const getAccountSummary = async (connectedUserAddress) => {
  const readOnlyLiquidSpaceContractInstance = await getLiquidSpaceContractWithSigner();
  try {
    const liquidName = await readOnlyLiquidSpaceContractInstance.functions.getAccountName();
    const liquidTokenBalance = await readOnlyLiquidSpaceContractInstance.functions.getLiquidTokenBalance();

    const allNftMetaData = await readOnlyLiquidSpaceContractInstance.functions.getNftMetaData();
    const accountNfts = allNftMetaData.filter((nft) => {
      if (nft.owner === connectedUserAddress) {
        return nft;
      }
    });

    const accountSummary = {
      liquidName: liquidName,
      liquidTokenBalance: liquidTokenBalance,
      liquidNftSummary: accountNfts.length + 1, //+1 due to liquid name being and NFT
    };
    return accountSummary;
  } catch (error) {
    console.error(`Error in getting account summary: ${JSON.stringify(error, null, 2)}`);
  }
};

export const registerLiquidName = async (liquidName) => {
  const liquidSpaceContractInstanceWithSigner = await getLiquidSpaceContractWithSigner();
  try {
    return await liquidSpaceContractInstanceWithSigner.registerLiquidNameToAccount(liquidName);
  } catch (error) {
    console.error(`Error in registering liquid name: ${JSON.stringify(error, null, 2)}`);
  }
};
