import { useState } from 'react';

// Component
import Button from '../ui/Button';
// Images
import CameraIcon from '../ui/images/camera-icon.svg';
// Styling
import styling from './CreateNftsForm.module.css';

const CreateNftsForm = (props) => {
  const [nftMetaData, setNftMetaData] = useState({
    file: '',
    name: '',
    description: '',
    attributes: [],
    royalty: '',
    supplyQuantity: '',
  });

  const [nftAttribute, setNftAttribute] = useState({
    attributeKey: '',
    attributeValue: '',
  });

  // manage image drag, drop and state
  const imageDragOverHandler = (event) => {
    event.preventDefault();
  };

  const imageDropHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();

    let imageFile = event.dataTransfer.files[0];

    //TODO: better error handling here
    //TODO: add input validation
    if (['image/png', 'image/jpeg', 'image/gif'].includes(imageFile.type)) {
      nftFileChangeHandler(imageFile);
    } else {
      alert(`Please upload a supported NFT type`);
    }
  };

  const nftFileChangeHandler = (draggedFile) => {
    const fileObj = draggedFile;
    if (!fileObj) {
      return;
    }

    setNftMetaData((previousState) => {
      return {
        ...previousState,
        file: draggedFile,
      };
    });
  };

  // submit form
  const submitForm = (event) => {
    event.preventDefault();
    props.onCreateNft(nftMetaData);

    resetNftMetaData();
  };

  // reset nft meta data and attributes
  const resetNftMetaData = () => {
    setNftMetaData({
      file: '',
      name: '',
      description: '',
      attributes: [],
      royalty: '',
      supplyQuantity: '',
    });
    resetNftAttributes();
  };

  const resetNftAttributes = () => {
    setNftAttribute({
      attributeKey: '',
      attributeValue: '',
    });
  };

  //NFT attributes

  const nftAttributesChangeHandler = (event) => {
    setNftAttribute((previousState) => {
      return { ...previousState, [event.target.name]: event.target.value };
    });
  };

  const nftAttributesAddHandler = () => {
    const existingAttributes = nftMetaData.attributes;
    existingAttributes.push(nftAttribute);
    resetNftAttributes();
    setNftMetaData((previousState) => {
      return { ...previousState, attribute: existingAttributes };
    });
  };

  const nftAttributesDeleteHandler = (index) => {
    const existingAttributes = nftMetaData.attributes;
    existingAttributes.splice(index, 1);
    setNftMetaData((previousState) => {
      return { ...previousState, attribute: existingAttributes };
    });
  };

  // manage royalty change
  const nftPriceChangeHandler = (event) => {
    setNftMetaData((previousState) => {
      return { ...previousState, royalty: Math.floor(event.target.value) };
    });
  };

  //general nft meta data handler
  const nftMetaDataChangeHandler = (event) => {
    setNftMetaData((previousState) => {
      return { ...previousState, [event.target.name]: event.target.value };
    });
  };

  return (
    <>
      <h1 className={styling.formStart}> GENERAL</h1>
      <div className={styling.uploadedImage}>
        <div onDragOver={imageDragOverHandler} onDrop={imageDropHandler}>
          {!nftMetaData.file && (
            <img src={CameraIcon} alt="Placeholder to indicate where NFTs can be drag and dropped"></img>
          )}
          {nftMetaData.file && (
            <img
              src={URL.createObjectURL(nftMetaData.file)}
              alt="Preview of uploaded NFT"
              className={styling.previewImageCircle}
            />
          )}
          <h2 className={styling.formStart}> Drag and drop your NFT above</h2>
          <p className={styling.formStart}> Supported NFTs: JPG, PNG, GIF up to 10 mb</p>
        </div>
      </div>
      <div className={styling.formContainer}>
        <div className={styling.nameDesc}>
          <input
            name="name"
            type="text"
            value={nftMetaData.name}
            className={styling.name}
            placeholder="Name"
            onChange={nftMetaDataChangeHandler}
          />
          <textarea
            name="description"
            type="textarea"
            className={styling.description}
            value={nftMetaData.description}
            placeholder="Description"
            onChange={nftMetaDataChangeHandler}
          />
        </div>
        <h1 className={styling.formStart}> PROPERTIES </h1>
        {nftMetaData.attributes.length > 0 &&
          nftMetaData.attributes.map((addedAttribute, index) => (
            <div className={styling.attributeValue} key={index}>
              <p>{addedAttribute.attributeKey},</p>
              <p>{addedAttribute.attributeValue}</p>
              <button
                key={index}
                className={styling.xButton}
                onClick={() => {
                  nftAttributesDeleteHandler(index);
                }}
              >
                X
              </button>
            </div>
          ))}
        <div className={styling.attributeSection}>
          <input
            name="attributeKey"
            type="text"
            value={nftAttribute.attributeKey}
            className={styling.attribute}
            placeholder="Attribute"
            onChange={nftAttributesChangeHandler}
          />
          <input
            name="attributeValue"
            type="text"
            value={nftAttribute.attributeValue}
            className={styling.attribute}
            placeholder="Value"
            onChange={nftAttributesChangeHandler}
          />
          <Button buttonStyle={styling.addButton} buttonType={'button'} handler={nftAttributesAddHandler}>
            Add
          </Button>
        </div>
        <p className={styling.attributeText}>
          Protect your NFT from counterfeits by giving it some unique attributes. For example: Attribute = Colour, Value
          = Green
        </p>
        <div className={styling.nameDesc}>
          <h1 className={styling.formStart}> NFT SUPPLY DETAILS </h1>
          <select
            className={styling.select}
            value={nftMetaData.supplyQuantity}
            name="supplyQuantity"
            onChange={nftMetaDataChangeHandler}
          >
            <option defaultValue="">Supply Quantity</option>
            <option value="1">One</option>
          </select>
          <input
            name="royalty"
            type="number"
            value={nftMetaData.royalty}
            className={styling.name}
            placeholder="% royalty you would be paid in Liquid Token"
            onChange={nftPriceChangeHandler}
          />
        </div>
        <div className={styling.bottomButtons}>
          <Button buttonStyle={styling.discardButton} buttonType={'button'} handler={resetNftMetaData}>
            Discard
          </Button>
          <Button buttonType={'submit'} handler={submitForm}>
            Create
          </Button>
        </div>
      </div>
    </>
  );
};

export default CreateNftsForm;
