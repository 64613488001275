import Dalle1 from '../components/ui/images/Dalle1.png';
import Dalle2 from '../components/ui/images/Dalle2.png';
import Dalle3 from '../components/ui/images/Dalle3.png';
import Dalle4 from '../components/ui/images/Dalle4.png';

export const processCloudFlareImageSrc = (unprocessedSrc) => {
  let splitUrlSrc = unprocessedSrc.slice(7, unprocessedSrc.length);
  return `https://cloudflare-ipfs.com/ipfs/${splitUrlSrc}`;
};

export const nftDummyData = [
  {
    name: 'LiquidSpace',
    ownerUsername: 'Alex',
    ownerAddress: '0xcd3B766CCDd6AE721141F452C550Ca635964ce71',
    price: '10',
    isForSale: false,
    nftInformation: {
      creator: 'Alex',
      lastSalePrice: '50',
      attributes: [
        {
          variant: 'Modern',
        },
      ],
      nftID: 101,
      displayType: 'Portrait',
      imageSrc: Dalle1,
      // 175 char limit proposed
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation',
    },
    transactionHistory: [],
  },
  {
    name: 'Gopher',
    ownerUsername: 'Alex',
    ownerAddress: '',
    price: '1',
    isForSale: true,
    nftInformation: {
      creator: 'Alex',
      lastSalePrice: '50',
      attributes: [
        {
          variant: 'Modern',
        },
      ],
      nftID: 101,
      displayType: 'Portrait',
      imageSrc: Dalle2,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation',
    },
    transactionHistory: [],
  },
  {
    name: 'Roman Engineer',
    ownerUsername: 'Alex',
    ownerAddress: '0xcd3B766CCDd6AE721141F452C550Ca635964ce71',
    price: '15',
    isForSale: true,
    nftInformation: {
      creator: 'Alex',
      lastSalePrice: '50',
      attributes: [
        {
          variant: 'Modern',
        },
      ],
      nftID: 101,
      displayType: 'Portrait',
      imageSrc: Dalle3,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation',
    },
    transactionHistory: [],
  },
  {
    name: 'Alice in Wonderland',
    ownerUsername: 'Alex',
    ownerAddress: '0xcd3B766CCDd6AE721141F452C550Ca635964ce71',
    price: '18',
    isForSale: true,
    nftInformation: {
      creator: 'Alex',
      lastSalePrice: '50',
      attributes: [
        {
          variant: 'Modern',
        },
      ],
      nftID: 101,
      displayType: 'Portrait',
      imageSrc: Dalle4,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation',
    },
    transactionHistory: [],
  },
];
