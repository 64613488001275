//external libs
import { useNavigate } from 'react-router-dom';

//components
import Button from '../ui/Button';
import XXDecorationBar from '../ui/XXDecorationBar';

//styling
import styling from './ConnectWallet.module.css';

//others
import metaMaskWallet from './metamask-wallet.svg';

const ConnectWallet = () => {
  const navigate = useNavigate();

  const connectWalletHandler = () => {
    if (typeof window.ethereum !== 'undefined') {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(() => {
          navigate('/account');
        })
        .catch(() => {
          alert("Looks like you don't have the Metamask extension installed. Go and install it and try again");
        });
    }
  };

  return (
    <>
      <section className={styling.container}>
        <XXDecorationBar />
        <section className={styling.wrapper}>
          <div className={styling.blurb}>
            <h1>
              connect<br></br>your wallet
            </h1>
            <p>
              Quis cillum exercitation voluptate excepteur. Elit cupidatat cupidatat eiusmod incididunt dolore eu esse
              do in officia. Quis cillum exercitation voluptate excepteur. Elit cupidatat cupidatat eiusmod incididunt
              dolore eu esse do in officia.
            </p>
            <Button handler={connectWalletHandler}>Connect Wallet</Button>
          </div>
          <div className={styling.image}>
            <img src={metaMaskWallet} alt="a MetaMask wallet" />
          </div>
        </section>
        <XXDecorationBar />
      </section>
    </>
  );
};

export default ConnectWallet;
