//styling
import styling from './ProfilePicturePlaceholder.module.css';
import camera from '../ui/images/camera.svg';

const ProfilePicturePlaceholder = () => {
  return (
    <div className={styling.container}>
      <img src={camera} alt="a camera" />
    </div>
  );
};

export default ProfilePicturePlaceholder;
