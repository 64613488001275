//components
import LinkingList from '../ui/LinkingList';

//styling
import styling from './TopBar.module.css';

const TopBar = () => {
  return (
    <div className={styling.container}>
      <ul>
        <LinkingList to={'/'} title={'Liquid Space'} />
      </ul>
      <ul>
        <LinkingList to={'/connect'} title={'Get Started'} />
      </ul>
    </div>
  );
};

export default TopBar;
