//external libs
import { useEffect, useState } from 'react';

//components
import NavBar from '../components/ui/NavBar';
import AccountSummaryPanel from '../components/account/AccountSummaryPanel';
import AccountAssets from '../components/account/AccountAssetsTab';
import Footer from '../components/ui/Footer';

//styling
import styling from './AccountPage.module.css';
import { nftDummyData } from '../helpers/helpers';

function AccountPage(props) {
  let smartContractArrayOfNFTs = nftDummyData;
  const [NFTPayload, setNFTPayload] = useState([]);
  useEffect(() => {
    setNFTPayload(smartContractArrayOfNFTs.reverse());
  }, []);

  return (
    <>
      <NavBar />
      <div className={styling.container}>
        <AccountSummaryPanel thisUserAddress={props.thisUserAddress} />
        <div className={styling.nftGalleryContainer}>
          <AccountAssets NFTPayload={NFTPayload} thisUserAddress={props.thisUserAddress} />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AccountPage;
