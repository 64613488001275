//styling
import styling from './XXDecorationBar.module.css';

//others
import xxSingle from './images/xx-decoration-single.svg';

export const XXDecorationBar = () => {
  return (
    <>
      <div className={styling.outer}>
        <div>
          <img src={xxSingle} className={styling.leftX} alt="Left Decoration X" />
        </div>

        <div>
          <img src={xxSingle} className={styling.rightX} alt="Right Decoration X" />
        </div>
      </div>
    </>
  );
};

export default XXDecorationBar;
