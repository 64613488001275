//styling
import styling from './AccountAssets.module.css';

//components
import NFTGallery from '../../components/shared/NFTGallery';

const AccountAssets = (props) => {
  let classes = props.isActive ? styling.contentActive : styling.contentInactive;

  return (
    <>
      {props.isActive && props.type === 'nft' && props.NFTPayload.length > 0 ? (
        <NFTGallery columnCount={3} NFTPayload={props.NFTPayload} thisUserAddress={props.thisUserAddress} />
      ) : (
        <>
          <div className={classes}>
            <p>{props.message}</p>
          </div>
        </>
      )}
    </>
  );
};

export default AccountAssets;
