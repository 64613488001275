//external libs
import { Link } from 'react-router-dom';

//components
import XXXXBlock from './XXXXBlock';
import LinkingList from './LinkingList';

//styling
import styling from './Footer.module.css';

const Footer = () => {
  return (
    <div className={styling.container}>
      <Link to="/">
        <li>
          <h1>LIQUID SPACE</h1>
        </li>
      </Link>
      <div className={styling.wrapper}>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua.
        </p>
        <div className={styling.listWrapper}>
          <ul className={styling.list}>
            <LinkingList to={'/marketplace'} title={'Marketplace'} />
          </ul>
        </div>
      </div>
      <XXXXBlock type={'rectangle'} />
    </div>
  );
};

export default Footer;
