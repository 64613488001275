//components
import TopBar from '../components/landing-page/TopBar';
import Banner from '../components/landing-page/Banner';
import ScrollDownPageBreak from '../components/landing-page/ScrollDownPageBreak';
import WelcomeMessage from '../components/landing-page/WelcomeMessage';
import ScrollingText from '../components/landing-page/ScrollingText';
import UserSteps from '../components/landing-page/UserSteps';
import Footer from '../components/ui/Footer';

function LandingPage() {
  return (
    <>
      <TopBar />
      <Banner />
      <ScrollDownPageBreak />
      <WelcomeMessage />
      <ScrollingText />
      <UserSteps />
      <Footer />
    </>
  );
}

export default LandingPage;
