import { ethers } from 'ethers';

export const getSigner = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
  const signer = provider.getSigner();
  return signer;
};

export const getWeb3Provider = async () => {
  return new ethers.providers.Web3Provider(window.ethereum, 'any');
};

export const getAccountAddressAndEthBalance = async () => {
  try {
    const signer = await getSigner();
    const signerAddress = await signer.getAddress();
    const balanceInWei = await signer.getBalance();
    const balanceInEth = ethers.utils.formatEther(balanceInWei);
    return { signerAddress: signerAddress, accountEthBalance: balanceInEth };
  } catch (error) {
    console.error(`Error in getting account address and eth balance: ${JSON.stringify(error, null, 2)}`);
  }
};

export const getAccountAddress = async () => {
  try {
    const signer = await getSigner();
    const signerAddress = await signer.getAddress();
    return { signerAddress: signerAddress };
  } catch (error) {
    console.error(`Error in getting account address ${JSON.stringify(error, null, 2)}`);
  }
};

//@todo: first function to move to BE.
export const rewardEth = async (reciverAddress) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
  const wallet = new ethers.Wallet(process.env.REACT_APP_PRIVATE_KEY, provider);
  const ethToReward = '0.01';
  const transaction = {
    to: reciverAddress,
    value: ethers.utils.parseEther(ethToReward),
  };
  return wallet.sendTransaction(transaction).catch((error) => {
    console.error(
      `Error in rewarding Goerli ETH: ${JSON.stringify(error)}. Transaction details: ${JSON.stringify(transaction)}`,
    );
  });
};
